var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Snow Editor"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSnow) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v("Snow is a clean, flat toolbar theme")]), _c('quill-editor', {
    attrs: {
      "options": _vm.snowOption
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }