var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Bubble Editor"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBubble) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v("Bubble is a simple tooltip based theme.")]), _c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }